import { Image, Typography } from "antd";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { useTitle } from "../hooks/useTitle";

type CardProps = {
  image: string;
  description: ReactNode;
  alignment: "left" | "right";
};

function AboutCard({ image, description, alignment }: CardProps) {
  return (
    <div
      className={`flex items-center ${
        alignment === "left" ? "flex-col-reverse" : "flex-col"
      } sm:flex-row my-8`}
    >
      {alignment === "left" && (
        <Image
          src={image}
          alt={image}
          preview={false}
          width={300}
          className="w-1/6 rounded-xl"
        />
      )}
      <Typography
        className={`${
          alignment === "left" ? "sm:mx-4" : ""
        } w-full sm:w-5/6 mb-4`}
      >
        <div className="text-gray-700 font-poppins">{description}</div>
      </Typography>
      {alignment === "right" && (
        <Image
          src={image}
          alt={image}
          preview={false}
          width={300}
          className="w-1/6 rounded-xl sm:mx-4"
        />
      )}
    </div>
  );
}

export default function About() {
  useTitle("About");
  return (
    <div className="w-full">
      <div id="content" className="pt-[2%]">
        <AboutCard
          image="/images/phone.png"
          description={
            <div className="flex flex-col">
              <span className="font-bold text-xl text-[#1cd494]">
                Your Everyday Needs, Fulfilled. Effortlessly.
              </span>
              <span className="my-4">
                In today's fast-paced world, finding reliable service providers
                can be a hassle. That's where we come in. Our platform connects
                you with a network of pre-screened professionals, ready to
                tackle your to-do list. From home repairs to pet care, we've got
                you covered.
              </span>
              <span className="mb-1 mt-2">
                Our cutting-edge technology streamlines the entire service
                experience:
              </span>
              <ul>
                <li>
                  <span className="font-bold">Request: </span>
                  Submit your service request in minutes.
                </li>
                <li>
                  <span className="font-bold">Choose: </span>
                  Select from top-rated providers based on reviews, price and
                  immediate availability.
                </li>
                <li>
                  <span className="font-bold">Track: </span>
                  Monitor service progress in real-time, from start to finish.
                </li>
                <li>
                  <span className="font-bold">Interact: </span>
                  Request and provide real-time feedback
                </li>
              </ul>
              <span className="font-bold mt-2">
                We're committed to providing you with an efficient,
                high-quality, and professional service experience every time.
                Let us take care of the details, so you can focus on what
                matters most to you.
              </span>
            </div>
          }
          alignment="left"
        />
        <AboutCard
          image="/images/about/about-2.jpg"
          description={
            <div>
              Pontres is your one-stop shop for connecting with qualified,
              independent service providers in your area. We offer a seamless
              and efficient platform to request, schedule, and manage a wide
              range of services, all from the convenience of your smartphone.
              Our cutting-edge technology ensures a smooth experience from start
              to finish, with real-time tracking, instant communication, and
              secure payments. Whether you need immediate assistance or want to
              schedule a future appointment, Pontres provides a cost-effective,
              reliable, and professional solution for all your service needs.
            </div>
          }
          alignment="right"
        />
        <AboutCard
          image="/images/about/about-3.jpg"
          description={
            <div className="flex flex-col">
              <span className="font-bold text-xl mb-4 text-[#1cd494]">
                How it works?
              </span>
              <span>
                Need help with a task right away? Simply open the Pontres app,
                select the service you require, and enter your location. Our
                intelligent system instantly identifies available providers near
                you, displaying them on a map. Choose the provider that best
                suits your needs, confirm your request, and within minutes,
                they'll be on their way. You can also schedule services for a
                future date and time, ensuring you have the help you need, when
                you need it. With Pontres, you're in control - track the
                provider's arrival, communicate directly through the app, and
                make secure payments. Available 24/7, Pontres is your reliable
                partner for everyday assistance.
              </span>
              <span className="font-bold mt-4">YOU NEED = WE OFFER</span>
            </div>
          }
          alignment="left"
        />
        <AboutCard
          image="/images/about/about-4.jpg"
          description={
            <div className="flex flex-col">
              <span className="font-bold text-xl mb-4 text-[#1cd494]">
                Customer Benefits
              </span>
              <span className="mb-4">
                Pontres empowers you to take control of your service needs.
                Request services anytime, anywhere, and enjoy the peace of mind
                that comes with a secure and efficient platform.
              </span>
              <ul>
                <li>
                  <span className="font-bold">Fast and Effecient: </span>
                  Get the help you need quickly with our on-demand service and
                  real-time tracking.
                </li>
                <li>
                  <span className="font-bold">Convenient Scheduling: </span>
                  Book services for immediate assistance or schedule them in
                  advance, with the option to set recurring appointments.
                </li>
                <li>
                  <span className="font-bold">Total Transparency: </span>
                  Monitor the service progress live and communicate directly
                  with your provider via text, audio, and image sharing.
                </li>
                <li>
                  <span className="font-bold">Secure and Reliable: </span>
                  Enjoy a safe and trustworthy platform with verified providers
                  and secure payment processing.
                </li>
              </ul>
            </div>
          }
          alignment="right"
        />
        <AboutCard
          image="/images/about/about-5.jpg"
          description={
            <div className="flex flex-col">
              <span className="font-bold text-xl mb-4 text-[#1cd494]">
                Provider Benefits
              </span>
              <span className="mb-4">
                Pontres offers a valuable platform for skilled professionals to
                connect with clients and grow their business.
              </span>
              <ul>
                <li>
                  <span className="font-bold">Expand Your Reach: </span>
                  Gain access to a wider client base and increase your earning
                  potential.
                </li>
                <li>
                  <span className="font-bold">Flexible Work: </span>
                  Set your own hours and manage your schedule with ease.
                </li>
                <li>
                  <span className="font-bold">Diverse Service Offerings: </span>
                  Offer a wide range of services, from specialized skills to
                  everyday tasks.
                </li>
                <li>
                  <span className="font-bold">Easy-to-Use Platform: </span>
                  Our intuitive app simplifies the process of connecting with
                  clients and managing your services.
                </li>
              </ul>
              <Link to="/auth/register" className="font-bold mt-2">
                <span className="underline text-black">
                  Visit our website today and register to become a Pontres
                  provider!
                </span>
              </Link>
            </div>
          }
          alignment="left"
        />
      </div>
    </div>
  );
}
